.root {
  --l-item-height: 112px;

  align-items: center;
  border: var(--border-decorative-secondary);
  border-radius: var(--rounded-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  height: var(--l-item-height);
  justify-content: center;
  padding: var(--spacing-8) var(--spacing-5) var(--spacing-4) var(--spacing-5);

  &.active {
    background: var(--color-sky-100);
    border: 2px solid var(--color-action);
  }
}
