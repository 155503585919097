:root {
  --button-spacing-horizontal: var(--spacing-3);
  --button-spacing-vertical: var(--spacing-2);
}

.root {
  align-items: center;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-md);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--text-xs);
  font-weight: var(--font-semibold);
  height: var(--button-size);
  /* Match the line-height of the icon */
  line-height: var(--button-icon-size);
  margin-bottom: 0;
  padding: 0 var(--button-spacing-horizontal);
  /* TODO: For flexbox, text-align does not work; use justify-content instead #4017 */
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: all ease var(--timing-300);
  user-select: none;
  white-space: nowrap;
  width: fit-content;

  &.square {
    border-radius: var(--rounded-none);
  }

  &.pill {
    border-radius: var(--rounded-2xl);
  }

  &:disabled:not(:global .loading),
  &.disabled:not(:global .loading) {
    background-color: var(--color-slate-200);
    border-color: var(--color-transparent);
    color: var(--color-slate-800);
    cursor: not-allowed;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  /* FontAwesomeIcon that does not use the Icon component */
  & :not(:global(.icon)):global(.svg-inline--fa) {
    font-size: var(--text-base);
    vertical-align: middle;
  }
}

.prefix,
.suffix {
  height: var(--button-icon-size);
}

.prefix {
  margin-right: var(--spacing-2);
}

.suffix {
  margin-left: var(--spacing-2);
}

.typeDanger {
  background-color: var(--color-red-600);
  border-color: var(--color-red-600);
  color: var(--color-red-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-red-700);
    border-color: var(--color-red-700);
    color: var(--color-red-50);
  }
}

.typeDangerSecondary {
  background-color: var(--color-white);
  border-color: var(--color-red-600);
  color: var(--color-red-600);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-red-700);
    border-color: var(--color-red-700);
    color: var(--color-red-50);
  }
}

.typeLink {
  background-color: inherit;
  border: none;
  color: var(--color-action);
  cursor: pointer;
  font-size: var(--text-sm);
  height: auto;
  justify-content: flex-start;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: var(--spacing-half);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: inherit;
    color: var(--color-action-hover);
  }
}

.typePrimary {
  background-color: var(--color-action);
  border-color: var(--color-action);
  color: var(--color-sky-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-action-hover);
    border-color: var(--color-action-hover);
    color: var(--color-sky-50);
  }
}

.typeIcon,
.typeIconOutlined {
  height: var(--button-with-icon-size);
}

.typeIconSmall {
  height: var(--button-icon-size);
  padding: 0;
  width: var(--button-icon-size);
}

.typeIcon {
  background-color: var(--color-transparent);
  border-color: var(--color-transparent);
  color: var(--color-slate-900);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-slate-200);
    border-color: var(--color-slate-200);
  }
}

.typeSecondary,
.typeIconOutlined {
  background-color: var(--color-bg-primary);
  border-color: var(--color-slate-300);
  color: var(--color-slate-900);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-bg-tertiary);
    border-color: var(--color-slate-300);
    color: var(--color-action);
  }
}

.typeClicked {
  background-color: var(--color-bg-tertiary);
  border-color: var(--color-slate-300);
  color: var(--color-action);
}

.typeNaked,
.typeIconSmall {
  background-color: inherit;
  border: none;
  color: inherit;

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: inherit;
    color: inherit;
  }
}

.typeTertiary {
  background-color: inherit;
  border-color: transparent;
  color: var(--color-action);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-bg-tertiary);
    border-color: var(--color-bg-tertiary);
    color: var(--color-action);
  }
}

.typeSuccess {
  background-color: var(--color-green-700);
  border-color: var(--color-green-700);
  color: var(--color-green-50);

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-green-800);
    border-color: var(--color-green-800);
    color: var(--color-green-50);
  }
}

.spinner {
  flex: 1;
  justify-content: center;
}

.fullWidth {
  /* TODO: Move justify-content to .root #4017 */
  justify-content: center;
  width: 100%;
}
