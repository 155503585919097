.metadata {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  font-size: var(--text-xs);
  gap: var(--spacing-4);
  justify-content: space-between;
  line-height: var(--leading-xs);

  & .daterange {
    display: block;
  }
}

.timeRange {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
}

.time {
  display: flex;
  flex-direction: row;
  font-size: var(--text-xs);
  gap: var(--spacing-1);
  line-height: var(--leading-xs);
}

.label {
  font-size: var(--text-xs);
  line-height: var(--leading-xs);
  margin-bottom: 0;
}
