.root {
  display: grid;
  gap: var(--spacing-4);
  grid-template-columns: 1fr 1fr;
  padding-right: var(--spacing-6);
}

.square {
  border-radius: var(--rounded-none);
}

.rounded {
  border-radius: var(--rounded-md);
}

.pill {
  border-radius: var(--rounded-2xl);
}

.active {
  border: var(--border-active);
}
