.root {
  align-items: center;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  transition: box-shadow 0.1s ease;
  /* Need a higher z-index to supersede the map component */
  z-index: 500;
}

.wrapper {
  border-bottom: 1px solid var(--color-decorative-secondary);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-2);

  padding: var(--spacing-4) var(--spacing-6);

  & .contentWrapper {
    display: flex;
    gap: var(--spacing-4);
    justify-content: flex-start;
    width: 100%;
  }
}

.collapsible {
  position: relative;
}

.sticky {
  position: sticky;

  &.scrolled {
    box-shadow: var(--drop-shadow-popover);
  }
}

.noncollapsible {
  composes: sticky;
}

.collapsed {
  composes: sticky;
}

.titleDescriptionActionsWrapper {
  display: flex;
  gap: var(--spacing-4);
  width: 100%;
}

.secondaryText {
  font-size: var(--text-xs);
  line-height: var(--leading-xs);
  /* margin-left: var(--spacing-4); */
  /* margin-top: var(--spacing-3); */
}

.titleWrapper {
  align-items: center;
  display: flex;
  max-width: var(--content-extra-width);

  & .title {
    margin: 0;

    & input {
      font-size: var(--text-3xl);
      font-weight: var(--font-bold);
      line-height: var(--leading-3xl);
    }
  }

  & .tooltip {
    max-width: 100%;
  }

  & .truncated {
    line-height: var(--leading-4xl);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .secondaryText {
    margin-left: var(--spacing-4);
    margin-top: var(--spacing-3);
  }
}

.description {
  margin-top: var(--spacing-1-half);

  /* Never have extra margin on the last <p> tag in the description */
  & p:last-child {
    margin-bottom: 0;
  }
}

& .addMaxWidth {
  max-width: var(--content-max-width-v2);
}

.actions {
  align-items: flex-end;
  display: flex;
  /* Prevent the .actions div from shrinking on smaller browsers */
  flex-shrink: 0;
  gap: var(--spacing-3);
  /* Align to the right of the flexbox */
  margin-left: auto;

  /* Remove the pesky margin on the bottom of the action toolbar */
  & > div {
    margin: 0;
  }
}

.tabs {
  margin-bottom: 0;
  padding: 0 var(--spacing-6);
  width: 100%;
}

.steps {
  width: 100%;

  /* Add padding and border to the container only if it's injected with content */
  &:not(:empty) {
    border-bottom: var(--border-decorative-secondary);
    padding: var(--spacing-4) var(--spacing-6);
  }
}

.children {
  border-bottom: var(--border-decorative-secondary);
  width: 100%;
}

@media (max-width: 880px) {
  .titleDescriptionActionsWrapper {
    align-items: flex-start;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  .actions {
    margin-left: initial;
  }
}
